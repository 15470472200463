import React, {useEffect, useState} from 'react';
import {Form, Input, Button, Upload, Typography, message, Spin, notification, Space} from 'antd';
import {UploadOutlined, PlusOutlined} from '@ant-design/icons';
import axios from 'axios';
import styled from 'styled-components';
import {makeAutoObservable, toJS} from "mobx";
import {observer} from "mobx-react";
import PrivacyConsentModal from "./PrivacyConsentModal";

const {Title, Paragraph} = Typography;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 70px;
    margin: 0 auto;
`;

const FormWrapper = styled.div`
    border-radius: 8px;
    margin: 0 150px;
    width: 100%;
    max-width: 1000px;

    @media (max-width: 768px) {
        margin: 0 40px;
    }
`;

const SectionContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
    width: 100%;

    @media (max-width: 1023px) {
        flex-direction: column;
        align-items: center;
    }
`;

const SectionWrapper = styled.div`
    background-color: #fff;
    padding: 40px 50px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    flex: 1;
    max-width: 100%;
    margin: 15px;

    @media (max-width: 1023px) {
        width: 90%;
        margin: 10px auto;
    }
    @media (max-width: 768px) {
        width: 100%;
        margin: 10px auto;
    }
    @media (max-width: 576px) {
        width: 100%;
        padding: 20px 20px;
    }
`;

const FullWidthSectionWrapper = styled(SectionWrapper)`
    width: 100%;
    margin: 0;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const SubText = styled.div`
    padding-top: 5px;
    font-size: 12px;
`;

const CustomButton = styled(Button)`
    font-size: 16px;
    padding: 7px 20px;
    height: auto;
    width: 100%;
    background-color: #7031be;
    margin-top: 20px;
`;

const Footer = styled.div`
    font-size: 13px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
`;

const NoteWrapper = styled.div`
    border: 1px solid #ccc;
    padding: 16px;
    border-radius: 4px;
    margin-top: 16px;
    width: 100%;
    margin: 0 auto;
`;

class Store {
    private _fileList자료PDF: any[] = [];
    private _fileList사진자료: any[] = [];
    private _fileList명함: any[] = [];
    private _fileList기타: any[] = [];

    spinning = false;

    constructor() {
        makeAutoObservable(this);
    }

    get fileList자료PDF(): any[] {
        return this._fileList자료PDF;
    }

    set fileList자료PDF(value: any[]) {
        this._fileList자료PDF = value;
    }

    get fileList사진자료(): any[] {
        return this._fileList사진자료;
    }

    set fileList사진자료(value: any[]) {
        this._fileList사진자료 = value;
    }

    get fileList명함(): any[] {
        return this._fileList명함;
    }

    set fileList명함(value: any[]) {
        this._fileList명함 = value;
    }

    get fileList기타(): any[] {
        return this._fileList기타;
    }

    set fileList기타(value: any[]) {
        this._fileList기타 = value;
    }
}

const store = new Store();

const UserRequestForm: React.FC = () => {
    const [form] = Form.useForm();

    useEffect(() => {
        const storedFormData = sessionStorage.getItem('customerRequestFormData');
        if (storedFormData) {
            form.setFieldsValue(JSON.parse(storedFormData));
        }
    }, [form]);
    // modal 관련
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleModalOk = () => {
        form.submit();
        setIsModalVisible(false);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };


    const onFinish = async (values: any) => {
        try {
            let data = new FormData();
            let fieldsValue = form.getFieldsValue();

            let s = JSON.stringify(fieldsValue);
            console.log(s)

            data.append("form_data", s);
            toJS(store.fileList자료PDF).forEach((value) => {
                data.append('fileList자료PDF', value.originFileObj);
            });
            toJS(store.fileList사진자료).forEach((value) => {
                data.append('fileList사진자료', value.originFileObj);
            });
            toJS(store.fileList명함).forEach((value) => {
                data.append('fileList명함', value.originFileObj);
            });
            toJS(store.fileList기타).forEach((value) => {
                data.append('fileList기타', value.originFileObj);
            });

            store.spinning = true;

            const response = await axios.post('/api/upload', data, {});
            console.log(response);

            // 요청이 성공적으로 완료된 경우
            notification.success({
                message: '제출이 완료되었습니다',
                placement: 'bottom',
                duration: 2
            });
            console.log('제출이 완료되었습니다');
        } catch (error) {
            // 요청이 실패한 경우 (500 에러 포함)
            notification.error({
                message: '제출이 실패하였습니다',
                placement: 'bottom',
                duration: 2
            });
            console.error('제출에 실패하였습니다', error);
        } finally {
            store.spinning = false;
        }
    };


    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const handleUploadChange = (info: any, fileListSetter: (fileList: any[]) => void) => {
        let fileList = [...info.fileList];
        fileList = fileList.map(file => {
            if (file.response) {
                file.status = 'done';
            }
            return file;
        });
        fileListSetter(fileList);
    };

    const validateFileList = (_: any, value: any) => {
        return Promise.resolve();
        if(store.fileList사진자료.length < 5)
            return Promise.reject(new Error('사진자료는 최소 5매 이상이어야 합니다.'));
        return Promise.resolve();

        if (!value || value.length < 5) {
            return Promise.reject(new Error('사진자료는 최소 5매 이상이어야 합니다.'));
        }
        return Promise.resolve();
    };

    const handleFormChange = () => {
        const currentValues = form.getFieldsValue(true);
        sessionStorage.setItem('customerRequestFormData', JSON.stringify(currentValues));
    };

    console.log(form.getFieldsValue())
    return (
        <Container>
            <Title level={2}
                   style={{fontWeight: 'bold', textAlign: 'center', marginBottom: '50px', whiteSpace: 'nowrap'}}>
                메디프로필 멤버십 등록
            </Title>
            <FormWrapper>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={() => {
                        // message.error('필수항목을 입력해주세요');
                        notification.error({
                            message: '필수항목을 입력해주세요',
                            placement: 'bottom',
                            duration: 2
                        });
                    }}
                    onValuesChange={handleFormChange}
                    // onKeyDown={(e) => {
                    //     if (e.key === 'Enter') {
                    //         e.preventDefault();
                    //     }
                    // }}
                    style={{width: '100%', margin: '0 auto'}}
                    initialValues={{career: ['', '']}}
                >
                    <SectionContainer>
                        <SectionWrapper>
                            <Title level={5} style={{fontWeight: 'bold'}}>필수항목</Title>
                            <Form.Item
                                name="koreanName"
                                label="성명"
                                rules={[{required: true, message: '성명을 입력해주세요.'}]}
                            >
                                <Input placeholder="홍길동"/>
                            </Form.Item>
                            <Form.Item
                                name="englishName"
                                rules={[{required: true, message: '성명을 입력해주세요.'}]}
                            >
                                <Input placeholder="HONG, GILDONG"/>
                            </Form.Item>

                            <Form.Item>
                                <Form.Item
                                    name="phone"
                                    label={"연락처"}
                                    rules={[{required: true, message: '휴대전화를 입력해주세요.'}]}
                                >
                                    <Input placeholder="휴대전화"/>
                                </Form.Item>
                                <Form.Item name="email" rules={[{required: true, message: '이메일을 입력해주세요.'}]}>
                                    <Input placeholder="이메일"/>
                                </Form.Item>
                            </Form.Item>


                            <Form.Item
                                label="경력 / 자기소개"
                                required={true}
                                name={["career", 0]}
                                rules={[{required: true, message: '경력사항을 입력해주세요.'}]}
                            >
                                <Input placeholder="ex) 경영기획 전문가, 전)OO대학 겸임교수 등"/>
                            </Form.Item>

                            <Form.Item
                                required={true}
                                name={["career", 1]}
                                rules={[{required: true, message: '자기소개를 입력해주세요.'}]}
                            >
                                <Input.TextArea
                                    placeholder={"ex) 감성을 디지털로 혁신하다\nmediprofile.info"}
                                    rows={3}
                                />
                            </Form.Item>

                            <Form.List name="careerDetails">
                                {(fields, {add, remove}) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item
                                                required={true}
                                                {...field}
                                                validateTrigger={['onChange', 'onBlur']}
                                                rules={[{required: true, message: '경력사항을 입력해주세요.'}]}
                                                noStyle
                                            >
                                                <Form.Item
                                                    {...field}
                                                    noStyle
                                                >
                                                    <Input placeholder="ex) 경영기획 전문가, 전)OO대학 겸임교수 등"
                                                           style={{width: 'calc(100% - 65px)'}}/>
                                                </Form.Item>
                                                <Button
                                                    type="link"
                                                    onClick={() => remove(field.name)}
                                                    style={{
                                                        padding: 0,
                                                        height: 'auto',
                                                        lineHeight: '3',
                                                        marginLeft: '8px',
                                                        verticalAlign: 'middle'
                                                    }}
                                                >
                                                    삭제
                                                </Button>
                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                style={{width: '100%'}}
                                                icon={<PlusOutlined/>}
                                            >
                                                경력 추가
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                            <Form.Item>
                                <Form.Item
                                    label="사진자료"
                                    name={"사진자료"}
                                    required={true}
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                    rules={[{validator: validateFileList}]}
                                    validateTrigger={['onChange', 'onBlur']}
                                >
                                    <Upload
                                        listType="picture"
                                        maxCount={10}
                                        onChange={(info) => handleUploadChange(info, (fileList) => store.fileList사진자료 = fileList)}
                                    >
                                        <Button icon={<UploadOutlined/>}>업로드</Button>
                                    </Upload>
                                    <SubText>ex) 프로필사진 포함 최소 5매이상 10매 미만</SubText>
                                </Form.Item>
                            </Form.Item>
                        </SectionWrapper>
                        <SectionWrapper>
                            <Title level={5} style={{fontWeight: 'bold'}}>추가항목</Title>
                            <Form.List name="profile" initialValue={['']}>
                                {(fields, {add, remove}, {errors}) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item
                                                label={"소속"}
                                                required={false}
                                                key={field.key}
                                            >
                                                <Form.Item
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    noStyle
                                                >
                                                    <Input
                                                        placeholder="ex) 기관/직책(메디펀/대표이사)"
                                                        style={{width: '100%'}}
                                                        addonAfter={index > 0 ? <Button type="link"
                                                        onClick={() => remove(field.name)}>삭제</Button> : null}
                                                    />
                                                </Form.Item>
                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                style={{width: '100%'}}
                                                icon={<PlusOutlined/>}
                                            >
                                                대표 프로필 추가
                                            </Button>
                                            <Form.ErrorList errors={errors}/>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>

                            <Form.Item>


                                <Form.Item
                                    label="명함"
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                >
                                    <Upload
                                        listType="picture"
                                        maxCount={10}
                                        onChange={(info) => handleUploadChange(info, (fileList) => store.fileList명함 = fileList)}
                                    >
                                        <Button icon={<UploadOutlined/>}>업로드</Button>
                                    </Upload>
                                    <SubText>ex) 앞면, 뒷면, 고화질 사진, PDF등</SubText>
                                </Form.Item>

                                <Form.Item
                                    label="기타 자료"
                                >
                                    <Upload
                                        listType="picture"
                                        maxCount={10}
                                        onChange={(info) => handleUploadChange(info, (fileList) => store.fileList기타 = fileList)}
                                    >
                                        <Button icon={<UploadOutlined/>}>업로드</Button>
                                    </Upload>
                                    <SubText>ex) 회사자료, 개인자료, pdf, png 등 </SubText>
                                </Form.Item>

                                <Form.List name="sns" initialValue={['']}>
                                    {(fields, {add, remove}, {errors}) => (
                                        <>
                                            {fields.map((field, index) => (
                                                <Form.Item
                                                    label={index === 0 ? 'SNS' : ''}
                                                    required={false}
                                                    key={field.key}
                                                >
                                                    <Form.Item
                                                        {...field}
                                                        validateTrigger={['onChange', 'onBlur']}
                                                        noStyle
                                                    >
                                                        <Input
                                                            placeholder="ex) https://"
                                                            style={{width: '100%'}}
                                                            addonAfter={index > 0 ? <Button type="link"
                                                                                            onClick={() => remove(field.name)}>삭제</Button> : null}
                                                        />
                                                    </Form.Item>
                                                </Form.Item>
                                            ))}
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    style={{width: '100%'}}
                                                    icon={<PlusOutlined/>}
                                                >
                                                    SNS 추가
                                                </Button>
                                                <Form.ErrorList errors={errors}/>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>

                                <Form.List name="link" initialValue={['']}>
                                    {(fields, {add, remove}, {errors}) => (
                                        <>
                                            {fields.map((field, index) => (
                                                <Form.Item
                                                    label={index === 0 ? '동영상 링크주소' : ''}
                                                    required={false}
                                                    key={field.key}
                                                >
                                                    <Form.Item
                                                        {...field}
                                                        validateTrigger={['onChange', 'onBlur']}
                                                        noStyle
                                                    >
                                                        <Input
                                                            placeholder="ex) https://"
                                                            style={{width: '100%'}}
                                                            addonAfter={index > 0 ? <Button type="link"
                                                                                            onClick={() => remove(field.name)}>삭제</Button> : null}
                                                        />
                                                    </Form.Item>
                                                </Form.Item>
                                            ))}
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    style={{width: '100%'}}
                                                    icon={<PlusOutlined/>}
                                                >
                                                    동영상 링크 추가
                                                </Button>
                                                <Form.ErrorList errors={errors}/>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </Form.Item>
                        </SectionWrapper>
                    </SectionContainer>
                    <SectionWrapper id={"error-box-id"}>
                        <Title level={5} style={{fontWeight: 'bold'}}>비고</Title>
                        <NoteWrapper>
                            <Paragraph>
                                문의: 0507-1390-9493
                                <br/>
                                <br/>
                                예시 참고
                                <br/>
                                <a href="https://www.mediprofile.info/yutosol" target="_blank"
                                   rel="noopener noreferrer">www.mediprofile.info/yutosol</a>
                                <br/>
                                <a href="https://www.mediprofile.info/parkjaegon" target="_blank"
                                   rel="noopener noreferrer">www.mediprofile.info/parkjaegon</a>
                                <br/>
                                <a href="https://www.mediprofile.info/kimsungwon" target="_blank"
                                   rel="noopener noreferrer">www.mediprofile.info/kimsungwon</a>
                                <br/>
                                <a href="https://www.mediprofile.info/parkhwiseo" target="_blank"
                                   rel="noopener noreferrer">www.mediprofile.info/parkhwiseo</a>
                                <br/>
                                <a href="https://www.mediprofile.info/jeongseoyeon" target="_blank"
                                   rel="noopener noreferrer">www.mediprofile.info/jeongseoyeon</a>
                                <br/>
                                <a href="https://www.mediprofile.info/parkgyuri" target="_blank"
                                   rel="noopener noreferrer">www.mediprofile.info/parkgyuri</a>
                            </Paragraph>
                        </NoteWrapper>
                        <PrivacyConsentModal
                            visible={isModalVisible}
                            onOk={handleModalOk}
                            onCancel={handleModalCancel}
                        />
                        <ButtonWrapper>
                            <CustomButton type="primary" onClick={showModal}>
                                제출하기
                            </CustomButton>
                        </ButtonWrapper>
                    </SectionWrapper>
                    <Spin spinning={store.spinning}/>
                </Form>
            </FormWrapper>
            <Footer style={{textAlign: 'center'}}>Created by Medifun Inc.</Footer>
        </Container>
    );
};

export default observer(UserRequestForm);
